import React from "react";

function SolutionHeading () {
    return (
     <div className="border border-amber bg- rounded-md shadow-2xl ring-1 ring-gray-900/10 px-10 py-10  mx-auto max-w-5xl text-center">
        <h2 className="text-4xl font-bold tracking-tight text-amber sm:text-6xl">How can I send a mobile top-up?</h2>
          <p className="mt-6 text-lg leading-8 text-navy">
           Simply SMS JUSA to 45776 to get started, and follow our easy steps to place your order.
           There is no registration required, nor an app to download. We use simple SMS technology to make your life easier.
           And if you are making a cash payment, you do not need mobile data to use the service.
           Standard SMS rates apply.
          </p>
     </div>)
  }

  export default SolutionHeading;