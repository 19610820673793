import React from "react";
import Image from ".././assets/image1.png";
import Photo from ".././assets/image5.png";
import Spaza from ".././assets/image19.png";
import Game from ".././assets/image20.png";
import Boxer from ".././assets/image21.png";


function SolutionOne() {
  return (
    <div className="relative isolate overflow-hidden bg-white shadow-3xl mt-12 px-4 py-12">
      <div className="mx-auto max-w-7xl px-6 pb-2 pt-10 sm:pb-10 lg:flex lg:px-8 lg:py-6 rounded-md shadow-2xl ring-1 text-navy/10">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
          <div className="flex flex-col lg:flex-row justify-center lg:justify-center"> {/* Use responsive classes to adjust the alignment */}
            <img
              className="h-12 mx-auto lg:mx-0 sm:pl-24 lg:pl-0" // Adjusted padding for mobile
              src={Photo}
              alt=""
            />
            <div className="mt-12 pl-4 sm:mt-32 lg:mt-16 lg:pl-8 text-center lg:text-left"> {/* Adjusted margin and text alignment for mobile */}
              <h1 className="mt-8 text-3xl font-bold tracking-tight text-navy sm:text-4xl"> {/* Adjusted font size for mobile */}
                Top-up with OTT Voucher
              </h1>
              <p className="mt-6 text-base leading-8 text-navy">
                Sending mobile top-up abroad is now even more fast, easy and simple with a <span className="text-[#FF7400] font-bold pr-2">CASH Voucher.</span>
                Buy an OTT Voucher that works just like an airtime voucher to top-up your loved ones' mobile phone. The voucher
                will have a 16-digit pin code, which you'll need to enter when prompted during the ordering process.
                <br />
                <br />
                Purchase a voucher at any nearby spaza shop or Kazang vendor in your area.
              </p>
              <div className="mt-2 md:mt-4 flex flex-wrap items-center justify-center lg:justify-start gap-x-6">
                <img src={Spaza} alt="" className="h-16" />
                <img src={Game} alt="" className="h-4" /> 
                <img src={Boxer} alt="" className="h-8" />
                
              </div>
            </div>
          </div>
        </div>
        <div className=" lg:block p-2 lg:pl-40">
          <img
            src={Image}
            alt=""
            width={390}
            height={150}
            className=""
          />
        </div>
        {/* </div> */}
        {/* </div> */}



      </div>
    </div>
  )
}

export default SolutionOne;