import React, { useState } from "react";
import { CheckIcon } from "@heroicons/react/outline";
import AirtimeForm from "./forms/AirtimeForm";
import ZesaForm from "./forms/ZesaForm";
import backgroundImage from '../assets/bgImage2png.png';

export default function Home() {
  const [selectedCountry, setSelectedCountry] = useState("zw");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("ZAR");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [meterNumber, setMeterNumber] = useState("");
  const [formType, setFormType] = useState("airtime");

  const INTERNATIONAL_PHONE_NUMBER_REGEX = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  const isValidPhoneNumber = (phoneNumber) => {
    return INTERNATIONAL_PHONE_NUMBER_REGEX.test(phoneNumber);
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  }

  const handleAirtimeSubmit = (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber) {
      alert(
        "Invalid recipient phone number. Please enter a valid Zimbabwean format phone number"
      );
      return;
    }

    if (currency === "USD" && amount < 0) {
      alert("Invalid amount. Please enter an amount not less than 0 USD.");
    } else if (currency === "USD" && amount > 50) {
      alert("Invalid amount. Please enter an amount between 0 and 50 USD.");
    } else if (currency === "ZAR" && (amount < 5 || amount > 1000)) {
      alert("Invalid amount. Please enter an amount between 5 and 1000 ZAR.");
    } else {
      // submit the form
    }
    if (currency === "USD" && (amount < 5 || amount > 1000));

    // Construct the message
    const formattedPhone = `+${phone}`;
    const message = `JUSA ${amount} ${currency} to ${formattedPhone}`;

    // Generate the SMS URL
    const smsUrl = `sms:45776;?&body=${encodeURIComponent(message)}`;

    // Navigate to the SMS URL
    window.location.href = smsUrl;
  };

  const handleZesaSubmit = (e) => {
    e.preventDefault();


    // Construct the message for ZesaForm
    const message = ` JUSA ${amount} ${currency} ZESA for  ${meterNumber}`;

    // Generate the SMS URL for ZesaForm
    const smsUrl = `sms:45776;?&body=${encodeURIComponent(message)}`;

    // Navigate to the SMS URL for ZesaForm
    window.location.href = smsUrl;
  };

  const handleCurrencyChange = (event) => {
    const currency = event.target.value;
    setCurrency(currency);
  };

  return (
    <div className=" md:h-[90vh] xl:h-auto bg-navy pt-12 lg:pt-16 lg:h-[113vh]  sm:h-[130vh] mt-24 sm:mt-12 max-w-full mx-auto">
      <div
        className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 sm:mt-0 mb-6 pt-4 md:pt-8 lg:pt-0 max-w-full mx-auto"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
        }}
      >
        <div className="pt-12 lg:pt-24 ml-4 sm:ml-12 md:ml-16 col-span-1 md:self-center lg:mt-2 sm:mt-0 max-w-auto">
          <h2
            className="text-center sm:text-center md:text-left lg:text-left font-poppins text-2xl sm:text-4xl md:text-5xl font-extrabold leading-normal text-white  tracking-loose"
          >
            Fast & easy way <br /> To send airtime <br /> Worldwide.
          </h2>

          <p className="pt-6 md:pt-12 sm:mx-0 pb-2 mt-4 text-left sm:text-center md:text-left text-sm sm:text-base md:text-lg font-roboto font-extrabold leading-6 text-navy">
            <ul className="flex flex-col items-center sm:items-start">

              <li>
                <div class="items-center border w-80 sm:w-80 sm:ml-20 lg:ml-2 mx-auto sm:px-2 px-2 py-2 mb-4 rounded-lg bg-white inline-block relative">
                  <div class="flex items-center">
                    <div class="w-8 h-8 rounded-full bg-amber flex items-center justify-center mr-2">
                      <CheckIcon class="h-5 w-4 text-white stroke-width=4" />
                    </div>
                    <div>
                      <span class="text-sm sm:text-base ml-l">
                        Pay with cash or EFT
                      </span>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div class="border w-80 sm:w-80 sm:ml-20 lg:ml-2 mx-auto px-2 py-2 mb-4 rounded-lg bg-white inline-block relative">
                  <div class="flex items-center">
                    <div class="w-8 h-8 rounded-full bg-amber flex items-center justify-center mr-2">
                      <CheckIcon class="h-5 w-5 text-white stroke-width=4" />
                    </div>
                    <div>
                      <span class="text-sm sm:text-base ml-l">
                        Buy as little as R10
                      </span>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div class="border w-80 sm:w-80 sm:ml-20 lg:ml-2 mx-auto px-2 py-2 mb-4 rounded-lg bg-white inline-block relative">
                  <div class="flex items-center">
                    <div class="w-8 h-8 rounded-full bg-amber flex items-center justify-center mr-2">
                      <CheckIcon class="h-5 w-5 text-white stroke-width=4" />
                    </div>
                    <div>
                      <span class="text-sm sm:text-base ml-l">
                        From the comfort of your home
                      </span>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div class="border w-80 sm:w-80 sm:ml-20 lg:ml-2 mx-auto px-2 py-2 mb-4 rounded-lg bg-white inline-block relative">
                  <div class="flex items-center">
                    <div class="w-8 h-8 rounded-full bg-amber flex items-center justify-center mr-2">
                      <CheckIcon class="h-5 w-5 text-white stroke-width=4" />
                    </div>
                    <div>
                      <span class="text-sm sm:text-base ml-l">
                        Text JUSA to 45776
                      </span>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div class="border w-80 sm:w-80 sm:ml-20 lg:ml-2 mx-auto px-2 py-2 mb-4 rounded-lg bg-white inline-block relative">
                  <div class="flex items-center">
                    <div class="w-8 h-8 rounded-full bg-amber flex items-center justify-center mr-2">
                      <CheckIcon class="h-5 w-5 text-white stroke-width=4" />
                    </div>
                    <div>
                      <span class="text-sm sm:text-base ml-l">
                        No registration required
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </p>
        </div>

        <div className="flex flex-col mt-24 md:mt-44 pb-12 items-center justify-self-center ">
          <div className="py-20 md:py-30 w-full sm:w-auto px-4 sm:px-2 lg:px-20 border border-gray-400 bg-white rounded-3xl items-center sm:items-center md:self-center lg:mt-4 lg:py-8 lg:mx-4">
            <p className="pb-2 md:pb-4 text-center font-poppins tracking-tighter text-xl xs:text-4xl sm:text-3xl md:text-xl  text-amber">
              Who are you sending top-up to?
            </p>
            <div className="btn-group py-4 flex items-center justify-center">
              <div className="pr-2">
                <button
                  type="button"
                  className={`${formType === "airtime"
                    ? "bg-amber text-white"
                    : "bg-white text-amber border border-amber"
                    } px-4 sm:px-12 xs:px-6 py-2 sm:py-2 rounded-full hover:bg-amber-dark`}
                  onClick={() => setFormType("airtime")}
                >
                  Buy Airtime
                </button>
              </div>

              <div>
                <button
                  type="button"
                  className={`${formType === "zesa"
                    ? "bg-amber text-white"
                    : "bg-white text-amber border border-amber"
                    } px-4 sm:px-12 xs:px-6 py-2 sm:py-2 rounded-full hover:bg-amber-dark`}
                  onClick={() => setFormType("zesa")}
                >
                  Buy ZESA
                </button>
              </div>
            </div>

            {formType === "airtime" ? (
              <AirtimeForm
                selectedCountry={selectedCountry}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                amount={amount}
                setAmount={setAmount}
                currency={currency}
                setCurrency={setCurrency}
                handleCurrencyChange={handleCurrencyChange}
                handleSubmit={handleAirtimeSubmit}
                onPhoneChange={handlePhoneChange}
              />
            ) : (
              <ZesaForm
                selectedCountry={selectedCountry}
                phoneNumber={phoneNumber}
                meterNumber={meterNumber}
                setMeterNumber={setMeterNumber}
                amount={amount}
                setAmount={setAmount}
                currency={currency}
                setCurrency={setCurrency}
                handleCurrencyChange={handleCurrencyChange}
                handleSubmit={handleZesaSubmit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

