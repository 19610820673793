import React from 'react';

const ScrollIndicator = () => {

  const [scrollTop, setScrollTop] = React.useState(0);

  const onScroll = () => {

    const windowScroll = document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight -  document.documentElement.clientHeight;
    const scrollPercentage = (windowScroll / height) * 100;
    
    setScrollTop(scrollPercentage);

  }

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [])


  return (
  <>
    <div className='bg-navy h-2 sticky top-0 z-10 left-0 w-full'>
      <div className='bg-amber h-2' style={{ width: `${scrollTop}%` }}></div>
    </div>
  </>
  )
}

export default ScrollIndicator;
