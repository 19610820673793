import React from 'react';
import image2 from ".././assets/flash-1.png";
import image12 from ".././assets/image12.png";
import image13 from ".././assets/image13.png";
import PicknPay from ".././assets/ppay.png";
import Shell from ".././assets/image23.png";
import Bp from ".././assets/bp.png";
import Capitec from ".././assets/image24.png";


export default function Homepage() {
  return (
    <div className="relative  isolate overflow-hidden bg-white shadow-3xl mt-12 px-4 py-12">

      <div className="mx-auto max-w-7xl px-6 pb-2 pt-10 sm:pb-10 lg:flex lg:px-8 lg:py-   rounded-md shadow-2xl ring-1 ring-navy/10">
        <div className="lg:pl-16 sm:pl-30 rounded-5xl mt-12 lg:rounded-2xl">

          <img
            src={image2}
            alt=""
            width="520"
            height="150"
            className="object-cover rounded-3xl"
          />
        </div>
        <div className="relative isolate overflow-hidden bg-white">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0">
              <div className="mb-2 flex lg:items-start lg:gap-x-6 ">
                <div className="">
                  <img className="h-36 lg:pl-28 sm:pl-16 " src={image12} alt="" />
                </div>
                <div className="mt-14">
                  <img className="h-9 lg:pl-28 " src={image13} alt="" />
                </div>
              </div>

              <div className="lg:ml-12 ">
                <h1 className="text-center lg:text-left lg:pl-24 text-4xl font-bold tracking-tight text-navy sm:text-4xl">
                  Send mobile top-up with 1Voucher
                </h1>
                <p className="text-center lg:text-left lg:pl-24 mt-6 text-base leading-8 text-navy">
                  Effortlessly recharge airtime for your dear ones using 1Voucher.
                  Enjoy the simplicity of secure online payments, ensuring your
                  family and friends never run out of talk time. Accessible from
                  over 200,000 locations nationwide. Connect with ease, no matter
                  the distance!
                </p>
                <div className="mt-2 lg:pl-24 flex flex-wrap items-center gap-x-6">
                  <img src={Capitec} alt="" className="h-16" />
                  <img src={PicknPay} alt="" className="h-5" />
                  <img src={Shell} alt="" className="h-6" />
                  <img src={Bp} alt="" className="h-7" />


                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
















