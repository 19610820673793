import React from "react";
import Image from ".././assets/image2.png";
import Yoco from ".././assets/image10.png";
import Vodapay from ".././assets/vodapay.png";
import MasterCard from ".././assets/image15.png";
import PayPal from ".././assets/image14.png";
import Visa from ".././assets/image17.png";
import Ned from ".././assets/nedbank.png";

function SolutionOne() {
  return (
    <div className="relative  isolate overflow-hidden bg-white shadow-3xl mt-12 px-4 py-12 ">
      <div className="mx-auto max-w-7xl px-6 pb-2 pt-10 sm:pb-10 lg:flex lg:px-8 rounded-md shadow-2xl ring-1 ring-navy/10">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
          <div className="mt-4 lg:pl-16 flex items-center gap-x-2">
            <img className="h-16" src={Ned} alt="" />
            <div className="flex items-center gap-x-2">
            <img className="h-12" src={Vodapay} alt="" />
            </div>
          </div>

          <div className="mt-32 lg:pl-24 sm:mt-32 lg:mt-16">
            <h1 className="flex text-center lg:text-left mt-2 text-4xl font-bold tracking-tight text-navy sm:text-4xl">
              We accept card and EFT payments
            </h1>
          </div>
          <p className="text-center lg:text-left mt-10 lg:pl-24 text-base leading-8 text-navy">

            In addition to paying with cash, you can easily recharge using
            <span className="text-amber font-bold pl-1 pr-2">
              Credit/Debit card and EFT.
            </span>
            Enjoy the convenience of topping up with your preffered payment
            method and continue to experience uninterrupted mobile top ups.
            <br />
            <br />
            Purchase a voucher at any nearby spaza shop or Flash vendor in your
            area.
          </p>
          <div className="mt-4 lg:pl-24 flex flex-wrap items-center gap-x-6">
            <img src={PayPal} alt="" className="h-6" />
            <img src={Yoco} alt="" className="h-12" />
            <img src={Visa} alt="" className="h-14" />
            <img src={MasterCard} alt="" className="h-14" />
          </div>
        </div>
        {/* <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32"> */}
        {/* <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none"> */}
        <div className="mb-6 lg:ml-32 pb-2">
          <img
            src={Image}
            alt=""
            // width={390}
            // height={150}
            className="w-full h-full mb-16 rounded-md"
          />
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default SolutionOne;
