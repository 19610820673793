import image2 from "../assets/pep.png";
import image4 from "../assets/mombe.png";
import image5 from "../assets/image24.png";
import image1 from "../assets/bp.png";
import image6 from "../assets/ackermans.png";
import image7 from "../assets/image19.png";
import image8 from "../assets/shoprite.png";
import image9 from "../assets/checkers.png";
import image10 from "../assets/mrprice.png";
import image11 from "../assets/picknpay.png";
import image12 from "../assets/ozow.png";
import image14 from "../assets/sasol.png";

const Images = [
  

{
  id: 1,
  src: image1,
  alt: "Image 1",
  },
  {
    id: 14,
    src: image14,
    alt: "Image 14",
    },
  {
    id: 2,
    src: image2,
    alt: "Image 2",
  },
  {
    id: 8,
    src: image8,
    alt: "Image 8",
  },
  {
    id: 12,
    src: image12,
    alt: "Image 12",
    },
  {
    id: 9,
    src: image9,
    alt: "Image 9",
  },
  {
    id: 7,
    src: image7,
    alt: "Image 7",
  },

  {
    id: 11,
    src: image11,
    alt: "Image 11",
    },
  {
    id: 5,
    src: image5,
    alt: "Image 5",
  },
    {
      id: 10,
      src: image10,
      alt: "Image 10",
      },

      {
        id: 6,
        src: image6,
        alt: "Image 6",
      },
  {
    id: 4,
    src: image4,
    alt: "Image 4",
  },

];

export default Images;