import React from 'react'

function ZesaForm({
  meterNumber,
  setMeterNumber,
  amount,
  setAmount,
  currency,
  handleCurrencyChange,
  handleSubmit,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div className=" relative">
        <p className="pb-2 md:pb-4 text-center font-poppins tracking-tighter text-xl xs:text-4xl sm:text-5xl md:text-xl text-amber">
          Available only in Zimbabwe!
        </p>
        <input
          type="text"
          placeholder="Enter Meter Number"
          className="w-full text-center tracking-tighter pl-4 sm:pl-6 py-3 rounded-full border border-gray-400 focus:outline-none focus:border-navy text-sm sm:text-base lg:uppercase placeholder-gray-500"
          pattern="^\d{9,13}$"
          title="Please enter a meter number with 9 to 13 digits."
          value={meterNumber}
          onChange={(e) => {
            setMeterNumber(e.target.value.replace(/\D/, ""));
          }}
          required
        />
      </div>
      <div className="mt-4 relative flex">
        <input
          id="amount-input"
          type="number"
          min="40"
          max="1000"
          placeholder={`Enter ZAR amount`}
          className="w-full md:w-auto lg:w-72 text-center tracking-tighter pl-2 sm:pl-20 md:pl-32 py-3 rounded-l-full border border-gray-400 focus:outline-none focus:border-navy text-sm sm:text-base lg:uppercase placeholder-gray-500"
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value.replace(/\D/, ""));
          }}
          required
        />
        <select
          value={currency}
          onChange={handleCurrencyChange}
          className="bg-gray-100 border border-gray-400 focus:outline-none focus:border-navy rounded-r-full py-3 pl-5 sm:text-sm  text-navy lg:uppercase"
        >
          <option value="ZAR">ZAR</option>
        </select>
      </div>
      <div className="mt-6">
        <button
          type="submit"
          className="w-full md:w-auto lg:w-full py-3 font-bold text-white uppercase bg-amber rounded-full hover:bg-amber focus:outline-none focus:shadow-outline-blue"
        >
          START TOP-UP
        </button>
      </div>
    </form>
  );
}

export default ZesaForm;