import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Images from "../data/carousel";
import { useMediaQuery } from 'react-responsive'

const Features4 = () => {

  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const isMediumScreen = useMediaQuery({ minWidth: 769, maxWidth: 1024 });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isSmallScreen ? 3 : (isMediumScreen ? 4 : 5),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
  };

  return (
    <div className="relative isolate overflow-hidden bg-white shadow-3xl mt-12 px-4 py-12">
      <div className="max-w-7xl py-10 sm:py-16 lg:py-16 px-10 mx-auto sm:px-16 lg:px-16 sm:flex sm:justify-center shadow-2xl ring-1 ring-navy/10">
        <div className="text-center">
          <h1 className="text-4xl mt-2 font-bold text-navy sm:text-6xl helight">
            WHERE TO GET VOUCHERS?
          </h1>
          <h2 className="text-2xl mt-6 font-bold tracking-tight text-amber sm:text-4xl">
            OUR PROVIDERS
          </h2>
          <p class="mx-auto max-w-4xl mt-6 py-4 pb-16 text-lg text-center text-navy">
            Our vouchers can be easily purchased at over 200,000 locations
            nationwide. No matter if you're on a workplace break, commuting, or
            running errands around town, you'll find options nearby to buy
            bundled minutes, data or messages. Retailers stocked with vouchers
            include corner stores, convenience shops in shopping centers, or
            your local Spaza - so keeping your account funded is simple and
            quick wherever life takes you.
          </p>
          <div className="mx-auto max-w-7xl md:max-w-4xl py-10 px-4 overflow-hidden">
            <Slider {...settings}>
              {Images.map((item) => (
                <div key={item.id}>
                  <img
                    src={item.src}
                    alt={item.alt}
                    className="h-24 w-56"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features4;
