import React, { useState } from 'react';
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";


const AirtimeForm = ({
  amount,
  setAmount,
  currency,
  handleCurrencyChange,
  handleSubmit,
  onPhoneChange,
}) => {
  const [error, setError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const validateAmount = (value, currency) => {
    const numericValue = Number(value);
    const isUSD = currency === "USD";
    const isInvalidAmount = isUSD ? (numericValue < 0 || numericValue > 50) : (numericValue < 5 || numericValue > 1000);

    setError(isInvalidAmount ? `Please enter an amount between ${isUSD ? 0 : 5} and ${isUSD ? 50 : 1000} ${currency}.` : "");
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    setPhoneError("");

    if (onPhoneChange) {
      onPhoneChange(value);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    validateAmount(amount, currency);

    // Validate phone number
    if (!phone || phone.length < 6) {
      setPhoneError("Please enter a valid phone number.");
      return;
    }

    // Check for any validation errors
    if (error || phoneError) {
      return;
    }
    handleSubmit(e);
  };

  const inputBorderStyle = error ? "border-red-500" : "border-gray-400";

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="relative ">
        <PhoneInput
          country={"zw"}
          value={phone}
          onChange={handlePhoneChange}
          inputStyle={{
            width: "100%",
            height: "3rem",
            padding: "0.75rem",
            fontSize: "1rem",
            borderRadius: "9999px",
            border: `1px solid ${inputBorderStyle}`,
            outline: "none",
            textAlign: "center",
            fontWeight: "bold",
            required: true,
            "@media (min-width: 767px)": {
              width: "50%",
            },
          }}

          containerStyle={{
            display: "block",
            width: "100%",
          }}
          dropdownStyle={{
            fontSize: "1rem",
            fontWeight: "normal",

          }}
          dropdownProps={{
            alignOptions: "right",
          }}
          rules={{ required: true }}

        />
        {phoneError && <p className="mt-2 text-red-500 text-sm">{phoneError}</p>}
      </div>
      <div className="mt-4 relative flex">
        <input
          type="text"
          id="amount"
          name="amount"
          min={currency === "USD" ? 1 : 5}
          max={currency === "USD" ? 1000 : 1000}
          placeholder={`Enter ${currency} amount`}
          className={`w-full text-center tracking-tighter pl-12 sm:pl-20 md:pl-16 py-3 rounded-l-full border ${inputBorderStyle} focus:outline-none focus:border-navy text-sm sm:text-base lg:uppercase placeholder-gray-500`}
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value.replace(/[^\d.]/g, ""));
            validateAmount(e.target.value, currency);
          }}
          step="0.01"
          required
        />
        <select
          value={currency}
          onChange={handleCurrencyChange}
          className="bg-gray-100 border border-gray-400 text-navy focus:outline-none focus:border-gray-400 rounded-r-full py-3 pl-5 sm:text-sm lg:uppercase"
        >
          <option value="ZAR">ZAR</option>
          <option value="USD">USD</option>
        </select>
      </div>
      {error && <p className="mt-2 text-red-500 text-sm">{error}</p>}
      <div className="mt-6">
        <button
          type="submit"
          className="w-full md:w-auto lg:w-full py-3 font-bold text-white uppercase bg-amber rounded-full hover:bg-amber focus:outline-none focus:shadow-outline-blue"
        >
          START TOP-UP
        </button>
      </div>
    </form>
  );
};

export default AirtimeForm;
